"use client";

import { cn } from "@/lib/utils";
import { CaretRightFill, ChevronDown, ChevronRight } from "@jengaicons/react";
import * as AccordionPrimitive from "@radix-ui/react-accordion";
import * as React from "react";

const Accordion = AccordionPrimitive.Root;

const AccordionItem = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Item>
>(({ className, ...props }, ref) => (
  <AccordionPrimitive.Item
    ref={ref}
    className={cn("overflow-hidden rounded-lg border", className)}
    {...props}
  />
));
AccordionItem.displayName = "AccordionItem";

const AccordionTrigger = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Trigger> & {
    noIcon?: boolean;
    containerStyles?: string;
    iconPosition?: "left" | "right";
    iconStyles?: string;
  }
>(
  (
    {
      className,
      children,
      noIcon = false,
      iconPosition = "left",
      containerStyles,
      iconStyles = "",
      ...props
    },
    ref,
  ) => (
    <AccordionPrimitive.Header className="flex">
      <AccordionPrimitive.Trigger
        ref={ref}
        className={cn(
          "text-bodyMd leading-bodyMd bg-subdued flex flex-1 items-center gap-2 px-3 py-2 font-medium [&[data-state=open]>svg]:rotate-90",
          iconPosition == "right" ? "flex-row-reverse justify-between" : "",
          className,
        )}
        {...props}
      >
        <>
          {!noIcon && (
            <ChevronRight
              className={cn(
                "text-soft h-4 w-4 shrink-0 bg-[inherit] transition-transform duration-200",
                iconStyles,
              )}
            />
          )}
          <span className={cn(containerStyles)}>{children}</span>
        </>
      </AccordionPrimitive.Trigger>
    </AccordionPrimitive.Header>
  ),
);
AccordionTrigger.displayName = AccordionPrimitive.Trigger.displayName;

const AccordionContent = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Content>
>(({ className, children, ...props }, ref) => (
  <AccordionPrimitive.Content
    ref={ref}
    className={cn(
      "data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down text-bodyMd overflow-hidden p-3",
      className,
    )}
    {...props}
  >
    {children}
  </AccordionPrimitive.Content>
));
AccordionContent.displayName = AccordionPrimitive.Content.displayName;

export { Accordion, AccordionItem, AccordionTrigger, AccordionContent };
