import { Flexsearch } from "@/app/(protected)/(content)/docs/_components/flexsearch";
import OutpostLogo from "@/components/assets/outpost-logo";
import { cn } from "@/lib/utils";
import {
  BookOpen,
  BookOpenFill,
  Briefcase,
  BriefcaseFill,
  ChevronRight,
  Cube,
  CubeFill,
  Jobs,
  JobsFill,
  PenNib,
  PenNibFill,
  Service,
  ServiceFill,
  ShieldCheck,
  ShieldCheckFill,
  Users,
  UsersFill,
} from "@jengaicons/react";
import { buttonVariants } from "@primitives/button";
import { Row } from "@primitives/layout";
import { Link } from "@primitives/link";
import React, { ReactNode } from "react";

import { Section, SectionBody } from ".";
import { WebsiteAuthHeader } from "./auth-header";
import { HeaderNavigationMenu } from "./list-item";
import MobileHeader from "./mobile-header";

export const products: {
  title: string;
  href: string;
  description: string;
  icon: any;
  iconFill?: any;
}[] = [
  {
    title: "Hub",
    href: "/docs/hub/get-started/overview",
    description: "Host pretrained models, datasets, and code.",
    icon: <Cube size={24} />,
    iconFill: <CubeFill size={24} />,
  },
  {
    title: "Services",
    href: "/docs/services/get-started/overview",
    description: "Deploy models and apps in production.",
    icon: <Service size={24} />,
    iconFill: <ServiceFill size={24} />,
  },
  {
    title: "Jobs",
    href: "/docs/jobs/get-started/overview",
    description: "Train, fine-tune or process data.",
    icon: <Jobs size={24} />,
    iconFill: <JobsFill size={24} />,
  },
  {
    title: "Enterprise",
    href: "/docs/enterprise/get-started/overview",
    description: "Setup SAML SSO and your AWS/GCP/Azure cloud.",
    icon: <ShieldCheck size={24} />,
    iconFill: <ShieldCheckFill size={24} />,
  },
];

export const resources: {
  title: string;
  href: string;
  description: string;
  icon: any;
  iconFill?: any;
}[] = [
  {
    title: "Docs",
    href: "/docs",
    description: "Start integrating Outpost’s products",
    icon: <BookOpen size={24} />,
    iconFill: <BookOpenFill size={24} />,
  },
  {
    title: "Blog",
    href: "/blog",
    description: "The latest from Outpost",
    icon: <PenNib size={24} />,
    iconFill: <PenNibFill size={24} />,
  },
];

export const company: {
  title: string;
  href: string;
  description: string;
  icon: any;
  iconFill?: any;
}[] = [
  {
    title: "About",
    href: "/about",
    description: "The story and team behind Outpost",
    icon: <Users size={24} />,
    iconFill: <UsersFill size={24} />,
  },
  {
    title: "Brand",
    href: "",
    description: "Assets and guidelines",
    icon: <Briefcase size={24} />,
    iconFill: <BriefcaseFill size={24} />,
  },
];

export function WebsiteHeader(props: { hideLinks?: boolean }) {
  return (
    <Section className="bg-subdued fixed inset-x-0 top-0 z-[1000] w-full border-b">
      <SectionBody className="flex h-[var(--site-header-height--mobile)] items-center justify-between gap-3 border-x-0 md:h-[var(--site-header-height--desktop)]">
        <Row className="items-center gap-3">
          <Link href="/">
            <OutpostLogo />
          </Link>
          {!props.hideLinks && <HeaderNavigationMenu />}
        </Row>

        <Row className="items-center gap-3">
          <WebsiteAuthHeader />
          <MobileHeader />
        </Row>
      </SectionBody>
    </Section>
  );
}

export function DocsHeader(props: { className?: string }) {
  return (
    <Section className="bg-subdued fixed inset-x-0 top-0 z-[1000] w-full border-b">
      <SectionBody
        className={cn(
          "flex h-[var(--site-header-height--mobile)] w-full items-center justify-between gap-3 border-x-0 md:h-[var(--site-header-height--desktop)]",
          props?.className,
        )}
      >
        <Row className="w-full items-center gap-3">
          <Link href="/">
            <OutpostLogo />
          </Link>
        </Row>
        <div className="hidden w-full md:block">
          <Flexsearch contentType="docs" />
        </div>

        <Row className="w-full items-center gap-3">
          <WebsiteAuthHeader />
          <MobileHeader />
        </Row>
      </SectionBody>
    </Section>
  );
}

export function AuthActions() {
  return (
    <div className="ml-auto hidden items-center gap-4 md:flex">
      <Link color="default" href="/signin" className="text-bodyLg font-medium">
        Log in
      </Link>
      <Link href="/signup" className={buttonVariants()}>
        <span>Sign up</span>
        <ChevronRight size="16" />
      </Link>
    </div>
  );
}

export function HeaderLink(props: { children: ReactNode; href: string }) {
  return (
    <Link
      href={props.href}
      className="text-bodyLg leading-bodyLg text-soft px-4 py-2 font-medium md:p-0"
    >
      {props.children}
    </Link>
  );
}
