import { cn } from "@/lib/utils";
import { HTMLProps } from "react";

function Skeleton({ className, as, ...props }: HTMLProps<HTMLDivElement>) {
  return (
    <div
      className={cn("bg-pressed animate-pulse rounded-lg", className)}
      {...props}
    />
  );
}

export { Skeleton };
