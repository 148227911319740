"use client";

import { useCurrentUser } from "@/lib/hooks/use-current-user";
import { cn } from "@/lib/utils";
import UserMenu from "@dashboard/components/navigation/header/shared/user-menu";
import { buttonVariants } from "@primitives/button";
import { Row } from "@primitives/layout";
import { Skeleton } from "@primitives/skeleton";
import Link from "next/link";

import { AuthActions } from "./header";

export function AuthHeaderSkeleton() {
  return (
    <div className="ml-auto flex flex-row items-center gap-3">
      <Skeleton className={cn("hidden h-9 w-36 sm:block")} />
      <Skeleton className={cn("hidden h-9 w-9 rounded-full sm:block")} />
    </div>
  );
}

export function WebsiteAuthHeader() {
  const { user, loading } = useCurrentUser();

  if (loading) return <AuthHeaderSkeleton />;

  if (!user) return <AuthActions />;

  return (
    <Row className="ml-auto gap-3">
      <Link
        href={`/${user?.name || ""}`}
        className={cn(
          buttonVariants({ variant: "outline" }),
          "hidden h-auto px-4 py-2 sm:block",
        )}
      >
        Go to dashboard
      </Link>
      <UserMenu className="hidden md:block" user={user} />
    </Row>
  );
}
