"use client";

import { outpostClientAPI } from "@dashboard/fetch/client";
import { RCurrentUser } from "@dashboard/user/types";
import { useEffect, useState } from "react";

export function useCurrentUser() {
  const [user, setUser] = useState<RCurrentUser | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchUser() {
      setLoading(true);
      try {
        const { data } = await outpostClientAPI.get<RCurrentUser>({
          path: `/user`,
          next: {
            tags: [`/user`],
          },
        });

        if (data) {
          setUser(data as RCurrentUser);
        }
      } catch (error) {
        console.error("Error fetching user:", error);
      } finally {
        setLoading(false);
      }
    }

    fetchUser();
  }, []);

  return { user, loading };
}
