import React from "react";

export default function OutpostLogo() {
  return (
    <div className="dark:invert">
      <svg
        width="104"
        height="24"
        viewBox="0 0 104 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.96572 23.6335C9.54983 23.6335 10.9717 22.9387 11.9431 21.8372C12.9146 22.9387 14.3364 23.6335 15.9205 23.6335C18.8485 23.6335 21.222 21.2599 21.222 18.332C21.222 17.2712 20.9105 16.2832 20.3739 15.4544C22.3309 14.6746 23.714 12.7626 23.714 10.5276C23.714 7.59963 21.3404 5.22607 18.4125 5.22607C17.9921 5.22607 17.5833 5.27498 17.1912 5.36743C16.8257 2.796 14.6151 0.818665 11.9427 0.818665C9.2031 0.818665 6.94885 2.89669 6.67018 5.56251C6.30657 5.4837 5.92906 5.44219 5.54186 5.44219C2.61392 5.44219 0.240356 7.81575 0.240356 10.7437C0.240356 12.9169 1.54803 14.7848 3.41945 15.6033C2.93997 16.4004 2.66421 17.334 2.66421 18.332C2.66421 21.2599 5.03778 23.6335 7.96572 23.6335ZM11.9772 18.022C14.9051 18.022 17.2787 15.6485 17.2787 12.7205C17.2787 9.7926 14.9051 7.41904 11.9772 7.41904C9.04922 7.41904 6.67566 9.7926 6.67566 12.7205C6.67566 15.6485 9.04922 18.022 11.9772 18.022Z"
          fill="black"
        />
        <path
          d="M102.053 19.6016C100.837 19.6016 99.8935 19.2584 99.2215 18.5719C98.5494 17.8855 98.2133 16.9201 98.2133 15.6759V9.92796H96.1756V7.73981H96.4974C97.0408 7.73981 97.4626 7.58249 97.7629 7.26786C98.0632 6.95322 98.2133 6.52417 98.2133 5.98071V5.07971H100.637V7.73981H103.275V9.92796H100.637V15.5686C100.637 15.969 100.701 16.3123 100.83 16.5983C100.959 16.87 101.166 17.0846 101.452 17.2419C101.738 17.3849 102.11 17.4564 102.568 17.4564C102.682 17.4564 102.811 17.4493 102.954 17.435C103.097 17.4206 103.233 17.4063 103.361 17.392V19.4729C103.161 19.5015 102.939 19.5301 102.696 19.5587C102.453 19.5873 102.239 19.6016 102.053 19.6016Z"
          fill="black"
        />
        <path
          d="M91.4131 19.5873C90.2541 19.6652 89.2155 19.4475 88.2973 18.9341C87.3931 18.4198 86.7341 17.6927 86.3202 16.7526L88.0847 15.7714C88.4351 16.3931 88.8883 16.8746 89.4443 17.2159C90.0142 17.5563 90.6273 17.7045 91.2836 17.6604C91.8421 17.6228 92.2875 17.4666 92.6197 17.1918C92.9519 16.917 93.1034 16.5632 93.0743 16.1303C93.0556 15.851 92.9637 15.6328 92.7989 15.4755C92.6331 15.3043 92.4277 15.1709 92.1828 15.0751C91.9519 14.9784 91.7159 14.9101 91.4747 14.8702L89.6606 14.4872C88.6644 14.2736 87.9031 13.904 87.3768 13.3783C86.8636 12.8377 86.5816 12.1904 86.5309 11.4364C86.485 10.7522 86.6196 10.147 86.9349 9.62082C87.2493 9.0807 87.7043 8.65036 88.2999 8.32978C88.8955 8.00921 89.5773 7.82311 90.3453 7.77149C91.3786 7.70203 92.3176 7.89841 93.1622 8.36062C94.0059 8.80887 94.6258 9.47555 95.0219 10.3607L93.2574 11.3419C92.9974 10.8123 92.6122 10.4034 92.1019 10.1151C91.6056 9.82585 91.0572 9.70141 90.4567 9.74177C89.9401 9.7765 89.5366 9.92986 89.2463 10.2019C88.9551 10.4599 88.8226 10.7844 88.8488 11.1754C88.8667 11.4407 88.9515 11.6594 89.1034 11.8315C89.2543 11.9897 89.4452 12.1172 89.6761 12.2138C89.9061 12.2966 90.1421 12.3649 90.3842 12.4187L92.264 12.8394C93.2174 13.0418 93.9647 13.4124 94.5058 13.9511C95.0461 14.4759 95.3425 15.1292 95.395 15.9112C95.4401 16.5814 95.2985 17.1871 94.9701 17.7282C94.6548 18.2543 94.1929 18.6851 93.5842 19.0206C92.9746 19.3421 92.2509 19.531 91.4131 19.5873Z"
          fill="black"
        />
        <path
          d="M79.4488 19.6233C78.3572 19.6233 77.3565 19.3644 76.4468 18.8466C75.5511 18.3288 74.8374 17.622 74.3056 16.7264C73.7738 15.8307 73.5078 14.816 73.5078 13.6824C73.5078 12.5348 73.7738 11.5202 74.3056 10.6385C74.8374 9.74279 75.5511 9.04304 76.4468 8.53921C77.3425 8.02139 78.3432 7.76248 79.4488 7.76248C80.5684 7.76248 81.569 8.02139 82.4507 8.53921C83.3464 9.04304 84.0532 9.74279 84.571 10.6385C85.1028 11.5202 85.3687 12.5348 85.3687 13.6824C85.3687 14.83 85.1028 15.8517 84.571 16.7473C84.0392 17.643 83.3254 18.3498 82.4297 18.8676C81.534 19.3714 80.5404 19.6233 79.4488 19.6233ZM79.4488 17.4191C80.1205 17.4191 80.7153 17.2582 81.2332 16.9363C81.751 16.6144 82.1568 16.1735 82.4507 15.6137C82.7586 15.0399 82.9126 14.3962 82.9126 13.6824C82.9126 12.9687 82.7586 12.3319 82.4507 11.7721C82.1568 11.2123 81.751 10.7714 81.2332 10.4495C80.7153 10.1277 80.1205 9.96671 79.4488 9.96671C78.791 9.96671 78.1962 10.1277 77.6644 10.4495C77.1466 10.7714 76.7337 11.2123 76.4258 11.7721C76.1319 12.3319 75.985 12.9687 75.985 13.6824C75.985 14.3962 76.1319 15.0399 76.4258 15.6137C76.7337 16.1735 77.1466 16.6144 77.6644 16.9363C78.1962 17.2582 78.791 17.4191 79.4488 17.4191Z"
          fill="black"
        />
        <path
          d="M60.3431 23.6334V7.73942H62.6596V10.1418L62.3808 9.58407C62.8098 8.92629 63.3817 8.4115 64.0967 8.03971C64.8117 7.66792 65.6268 7.48203 66.542 7.48203C67.643 7.48203 68.6297 7.74657 69.502 8.27565C70.3886 8.80474 71.0821 9.52687 71.5826 10.442C72.0974 11.3572 72.3548 12.3868 72.3548 13.5308C72.3548 14.6747 72.0974 15.7043 71.5826 16.6195C71.0821 17.5347 70.3957 18.2639 69.5234 18.8073C68.6512 19.3364 67.6573 19.601 66.542 19.601C65.6411 19.601 64.8189 19.4151 64.0753 19.0433C63.346 18.6715 62.7812 18.1352 62.3808 17.4346L62.7669 16.9841V23.6334H60.3431ZM66.306 17.3488C66.9924 17.3488 67.6001 17.1843 68.1292 16.8554C68.6583 16.5265 69.073 16.0761 69.3733 15.5041C69.6879 14.9178 69.8452 14.26 69.8452 13.5308C69.8452 12.8015 69.6879 12.1509 69.3733 11.5789C69.073 11.0069 68.6583 10.5564 68.1292 10.2276C67.6001 9.89866 66.9924 9.73422 66.306 9.73422C65.6339 9.73422 65.0262 9.89866 64.4828 10.2276C63.9537 10.5564 63.5319 11.0069 63.2173 11.5789C62.917 12.1509 62.7669 12.8015 62.7669 13.5308C62.7669 14.26 62.917 14.9178 63.2173 15.5041C63.5319 16.0761 63.9537 16.5265 64.4828 16.8554C65.0262 17.1843 65.6339 17.3488 66.306 17.3488Z"
          fill="black"
        />
        <path
          d="M57.8812 19.601C56.6657 19.601 55.722 19.2578 55.0499 18.5714C54.3778 17.885 54.0418 16.9198 54.0418 15.6757V9.92686H52.0041V7.73901H52.3258C52.8692 7.73901 53.291 7.58172 53.5913 7.26713C53.8916 6.95253 54.0418 6.52355 54.0418 5.98016V5.07928H56.4655V7.73901H59.1038V9.92686H56.4655V15.5685C56.4655 15.9689 56.5299 16.312 56.6586 16.598C56.7873 16.8697 56.9946 17.0842 57.2806 17.2415C57.5666 17.3845 57.9384 17.456 58.396 17.456C58.5104 17.456 58.6391 17.4489 58.7821 17.4346C58.9251 17.4203 59.0609 17.406 59.1896 17.3917V19.4723C58.9894 19.5009 58.7678 19.5295 58.5247 19.5581C58.2816 19.5867 58.0671 19.601 57.8812 19.601Z"
          fill="black"
        />
        <path
          d="M45.2526 19.6006C44.3803 19.6006 43.6153 19.4076 42.9575 19.0215C42.2997 18.6211 41.7849 18.0706 41.4131 17.3699C41.0557 16.6549 40.8769 15.8327 40.8769 14.9032V7.73911H43.3007V14.6887C43.3007 15.2178 43.4079 15.6825 43.6224 16.0829C43.8369 16.4833 44.1372 16.7979 44.5233 17.0267C44.9094 17.2412 45.3527 17.3485 45.8532 17.3485C46.368 17.3485 46.8184 17.2341 47.2045 17.0053C47.5906 16.7765 47.8909 16.4547 48.1054 16.04C48.3342 15.6253 48.4486 15.1392 48.4486 14.5815V7.73911H50.8509V19.3432H48.5558V17.0696L48.8132 17.3699C48.5415 18.0849 48.0911 18.6354 47.4619 19.0215C46.8327 19.4076 46.0963 19.6006 45.2526 19.6006Z"
          fill="black"
        />
        <path
          d="M33.8034 19.6009C32.7117 19.6009 31.711 19.3419 30.8012 18.8241C29.9055 18.3062 29.1917 17.5994 28.6598 16.7037C28.128 15.8079 27.862 14.7932 27.862 13.6595C27.862 12.5119 28.128 11.4971 28.6598 10.6154C29.1917 9.71964 29.9055 9.01984 30.8012 8.51598C31.697 7.99812 32.6977 7.7392 33.8034 7.7392C34.9231 7.7392 35.9238 7.99812 36.8055 8.51598C37.7013 9.01984 38.4081 9.71964 38.9259 10.6154C39.4578 11.4971 39.7237 12.5119 39.7237 13.6595C39.7237 14.8072 39.4578 15.8289 38.9259 16.7247C38.3941 17.6204 37.6803 18.3272 36.7845 18.8451C35.8888 19.3489 34.8951 19.6009 33.8034 19.6009ZM33.8034 17.3965C34.4752 17.3965 35.07 17.2355 35.5879 16.9136C36.1057 16.5917 36.5116 16.1508 36.8055 15.591C37.1134 15.0172 37.2674 14.3733 37.2674 13.6595C37.2674 12.9457 37.1134 12.3089 36.8055 11.7491C36.5116 11.1892 36.1057 10.7484 35.5879 10.4264C35.07 10.1045 34.4752 9.94358 33.8034 9.94358C33.1455 9.94358 32.5507 10.1045 32.0189 10.4264C31.501 10.7484 31.0881 11.1892 30.7802 11.7491C30.4863 12.3089 30.3393 12.9457 30.3393 13.6595C30.3393 14.3733 30.4863 15.0172 30.7802 15.591C31.0881 16.1508 31.501 16.5917 32.0189 16.9136C32.5507 17.2355 33.1455 17.3965 33.8034 17.3965Z"
          fill="black"
        />
      </svg>
    </div>
  );
}
