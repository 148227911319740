import { cn } from "@/lib/utils";
import { ArrowRightLg, List, X } from "@jengaicons/react";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@primitives/accordion";
import { buttonVariants } from "@primitives/button";
import { Column } from "@primitives/layout";
import { Link } from "@primitives/link";
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetTrigger,
} from "@primitives/sheet";
import { Text } from "@primitives/text";
import Image from "next/image";
import React from "react";

import { company, HeaderLink, products, resources } from "./header";

export default function MobileHeader() {
  return (
    <Sheet>
      <SheetTrigger className="ml-auto grid h-9 w-9 place-items-center md:hidden">
        <List size={16} />
      </SheetTrigger>
      <SheetContent
        overlayStyles="md:hidden"
        showClose={false}
        className="bg-subdued right-0 top-0 w-full overflow-y-auto p-0 sm:w-3/4 md:hidden"
        side="left"
      >
        <div className="flex min-h-[60px] w-full items-center justify-between border-b px-6">
          <Link href="/" className=" ">
            <Image
              alt="site-logo"
              width={113}
              height={25}
              className="h-[25px] w-[113px] dark:invert"
              priority
              src="/outpost-logo.svg"
            />
          </Link>
          <SheetClose>
            <X size={20} />
          </SheetClose>
        </div>
        <Column className="h-screen pt-8">
          <Column className="flex-1 gap-2 px-4">
            <Accordion type="single" collapsible>
              <AccordionItem value="1" className="m-0 border-none p-0">
                <AccordionTrigger iconPosition="right" className="px-4">
                  <Text
                    variant={"headingLg"}
                    weight={"medium"}
                    className="text-strong"
                  >
                    Products
                  </Text>
                </AccordionTrigger>
                <AccordionContent className="ml-1 mt-1 space-y-2 p-0">
                  {products.map((component) => (
                    <ListItem
                      key={component.title}
                      title={component.title}
                      href={component.href}
                      icon={component.icon}
                      description={component.description}
                    />
                  ))}
                </AccordionContent>
              </AccordionItem>
            </Accordion>
            <Accordion type="single" collapsible>
              <AccordionItem value="1" className="m-0 border-none p-0">
                <AccordionTrigger iconPosition="right" className="px-4">
                  <Text
                    variant={"headingLg"}
                    weight={"medium"}
                    className="text-strong"
                  >
                    Resources
                  </Text>
                </AccordionTrigger>
                <AccordionContent className="ml-1 mt-1 space-y-2 p-0">
                  {resources.map((component) => (
                    <ListItem
                      key={component.title}
                      title={component.title}
                      href={component.href}
                      icon={component.icon}
                      description={component.description}
                    />
                  ))}
                </AccordionContent>
              </AccordionItem>
            </Accordion>
            <Accordion type="single" collapsible>
              <AccordionItem value="1" className="m-0 border-none p-0">
                <AccordionTrigger iconPosition="right" className="px-4">
                  <Text
                    variant={"headingLg"}
                    weight={"medium"}
                    className="text-strong"
                  >
                    Company
                  </Text>
                </AccordionTrigger>
                <AccordionContent className="ml-1 mt-1 space-y-2 p-0">
                  {company.map((component) => (
                    <ListItem
                      key={component.title}
                      title={component.title}
                      href={component.href}
                      icon={component.icon}
                      description={component.description}
                    />
                  ))}
                </AccordionContent>
              </AccordionItem>
            </Accordion>

            <HeaderLink href="/pricing">
              <Text
                variant={"headingLg"}
                weight={"medium"}
                className="text-strong px-[1px]"
              >
                Pricing
              </Text>
            </HeaderLink>
          </Column>
          <Column className="gap-4 px-4 pb-48 pt-12">
            <Link
              color="default"
              href="/signin"
              className={buttonVariants({
                variant: "outline",
                fullWidth: true,
                className: "py-2",
              })}
            >
              Log in
            </Link>
            <Link
              target="_blank"
              href="/signup"
              className={buttonVariants({
                fullWidth: true,
                className: "py-2",
              })}
            >
              <span>Sign up</span>
              <ArrowRightLg size="16" />
            </Link>
          </Column>
        </Column>
      </SheetContent>
    </Sheet>
  );
}

export function ListItem(props: {
  title: string;
  href: string;
  description: string;
  icon: JSX.Element;
}) {
  return (
    <Link
      href={props.href}
      className="group flex h-[72px] w-full flex-row items-center justify-start rounded-lg px-2 py-6"
    >
      <div className="group-hover:text-primary bg-active flex size-[56px] min-w-[56px] items-center justify-center rounded-lg">
        {props.icon}
      </div>
      <div
        className={cn(
          "block select-none rounded-md p-3 leading-none no-underline outline-none transition-colors",
        )}
      >
        <div className="text-sm font-medium leading-none">{props.title}</div>
        <p className="text-muted-foreground text-soft line-clamp-2 text-sm leading-snug">
          {props.description}
        </p>
      </div>
    </Link>
  );
}
