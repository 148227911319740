"use client";

import { cn } from "@/lib/utils";
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
  navigationMenuTriggerStyle,
} from "@primitives/navigation-menu";
import { Text } from "@primitives/text";
import { AnimatePresence, LayoutGroup, motion } from "framer-motion";
import Link from "next/link";
import * as React from "react";

import { company, products, resources } from "./header";

export function HeaderNavigationMenu() {
  const [hoveredIndex, setHoveredIndex] = React.useState<number | null>(null);
  const [isAnyMenuOpen, setIsAnyMenuOpen] = React.useState(false);

  const menuItems = [
    { name: "Products", content: products },
    { name: "Resources", content: resources },
    { name: "Company", content: company },
    { name: "Pricing", href: "/pricing" },
  ];

  return (
    <NavigationMenu
      className="hidden px-4 md:block"
      delayDuration={0.2}
      onMouseLeave={() => {}}
    >
      <LayoutGroup id="header-navigation">
        <NavigationMenuList className="relative gap-0 px-0">
          {/* Add the moving triangle tip */}
          <AnimatePresence>
            {hoveredIndex !== null &&
              !isAnyMenuOpen &&
              !menuItems[hoveredIndex].href && (
                <motion.div
                  className="absolute top-full"
                  layoutId="triangle-tip"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.1 }}
                  style={{
                    left: `calc(${hoveredIndex * 25}% + 12.5% - 4px)`,
                  }}
                >
                  {/* Triangle tip */}
                  <div className="h-0 w-0 border-b-[6px] border-l-[6px] border-r-[6px] border-b-white border-l-transparent border-r-transparent" />
                </motion.div>
              )}
          </AnimatePresence>
          {menuItems.map((item, index) => (
            <NavigationMenuItem
              key={item.name}
              onMouseEnter={() => setHoveredIndex(index)}
              onMouseLeave={() => setHoveredIndex(null)}
              className="relative"
            >
              <AnimatePresence>
                {hoveredIndex === index && !isAnyMenuOpen && (
                  <motion.span
                    className="bg-active absolute inset-1.5 rounded-full"
                    layoutId="hoverBackground"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.2 }}
                  />
                )}
              </AnimatePresence>
              {item.href ? (
                <Link href={item.href} legacyBehavior passHref>
                  <NavigationMenuLink
                    className={cn(
                      navigationMenuTriggerStyle(),
                      "hover:text-default text-strong relative z-10",
                      hoveredIndex === index ? "text-strong" : "",
                    )}
                  >
                    {item.name}
                  </NavigationMenuLink>
                </Link>
              ) : (
                <>
                  <NavigationMenuTrigger
                    className={cn(
                      "hover:text-default text-strong relative z-10",
                      hoveredIndex === index && !isAnyMenuOpen
                        ? "text-strong"
                        : "",
                    )}
                  >
                    {item.name}
                  </NavigationMenuTrigger>
                  <NavigationMenuContent
                    onMouseEnter={() => setHoveredIndex(index)}
                    onMouseLeave={() => setHoveredIndex(null)}
                    className="NavigationMenuContent w-[400px] p-4 md:w-[500px] md:grid-cols-2 lg:w-[600px]"
                  >
                    <Text className="text-soft p-2 font-medium">
                      {item.name}
                    </Text>
                    <ul className="grid grid-cols-2 gap-3">
                      {item.content?.map((component) => (
                        <ListItem
                          key={component.title}
                          title={component.title}
                          href={component.href}
                          icon={component.icon}
                          iconFill={component.iconFill}
                        >
                          {component.description}
                        </ListItem>
                      ))}
                    </ul>
                  </NavigationMenuContent>
                </>
              )}
            </NavigationMenuItem>
          ))}
        </NavigationMenuList>
      </LayoutGroup>
    </NavigationMenu>
  );
}

export const ListItem = React.forwardRef<
  React.ElementRef<"a">,
  React.ComponentPropsWithoutRef<"a"> & {
    icon?: React.ReactNode;
    iconFill?: React.ReactNode;
  }
>(({ className, title, children, icon, iconFill, ...props }, ref) => {
  return (
    <li>
      <NavigationMenuLink asChild>
        <div className="text-soft group flex h-[72px] w-full flex-row items-center justify-start rounded-lg px-2 py-6">
          <div className="group-hover:text-primary bg-active flex size-[56px] min-w-[56px] items-center justify-center rounded-lg">
            <div className="group-hover:hidden">{icon}</div>
            <div className="group-hover:text-default hidden group-hover:block">
              {iconFill}
            </div>
          </div>
          <a
            ref={ref}
            className={cn(
              "group-hover:text-default block select-none rounded-md p-3 leading-none no-underline outline-none transition-colors",
              className,
            )}
            {...props}
          >
            <div className="text-sm font-medium leading-none">{title}</div>
            <p className="text-muted-foreground text-soft line-clamp-2 text-[12px] leading-snug">
              {children}
            </p>
          </a>
        </div>
      </NavigationMenuLink>
    </li>
  );
});
ListItem.displayName = "ListItem";
